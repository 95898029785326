import { Badge } from '~/components/ui/badge';
import { differenceInMinutes, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { RiMoreFill } from '@remixicon/react';
import {
  Appointments,
  EntityType_Enum,
  NotificationsQuery,
  NotificationsQueryVariables,
  Services,
  Users,
  useReadNotificationMutation,
} from '~/hasura/__generated__';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from '@remix-run/react';
import { ObservableQuery, Reference } from '@apollo/client';
import { buildNotificationElements } from './content';
import { fetchNotificationAffectedEntity } from './data-fetcher';
import { NotificationBadge } from './badge-builder';
import { Avatar } from '../avatar';
import { getEntityData } from './entity-data';

type Props = {
  imgSrc: string;
  setRenderNotifications: Dispatch<SetStateAction<boolean>>;
  updateNotificationsData: ObservableQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >['updateQuery'];
} & NotificationsQuery['notifications'][number];

export default function Notification({
  id,
  imgSrc,
  entityId,
  entityType,
  type,
  fromUser,
  readAt,
  createdAt,
  setRenderNotifications,
  updateNotificationsData,
}: Props) {
  const { t } = useTranslation();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const navigate = useNavigate();

  // check which entity type the notification is related to and returns the query to use
  const fetcherhook = fetchNotificationAffectedEntity(
    entityType as EntityType_Enum,
  );

  // gets the entity based on the query returned above
  const [fetcher, { loading, data }] = fetcherhook?.({
    variables: { id: entityId },
  }) || [undefined, {}];

  useEffect(() => {
    fetcher?.();
  }, [fetcher]);

  // gets the entity data based on the entity type
  const entityData = getEntityData(entityType as EntityType_Enum, data);

  const dropdownDivRef = useRef<HTMLDivElement>(null);
  const [readNotification] = useReadNotificationMutation({
    update: (cache, { data }) => {
      const readNotification = data?.update_notifications_by_pk;
      if (!readNotification) return;

      updateNotificationsData((prev) => {
        return {
          ...prev,
          unreadNotificationsCount: {
            ...prev.unreadNotificationsCount,
            aggregate: {
              ...prev.unreadNotificationsCount.aggregate,
              count: (prev.unreadNotificationsCount.aggregate?.count || 0) - 1,
            },
          },
        };
      });

      cache.modify({
        fields: {
          notifications: (
            existingNotifications: Readonly<Reference[]>,
            { readField },
          ) => {
            return existingNotifications.map((existingNotification) =>
              readField('id', existingNotification) === readNotification.id
                ? { ...existingNotification, readAt: readNotification.readAt }
                : existingNotification,
            );
          },
        },
      });
    },
  });

  const handleMarkAsRead = () => {
    setOptionsOpen(false);
    if (!readAt) {
      readNotification({ variables: { id, readAt: new Date().toUTCString() } });
    }
  };

  const handleView = () => {
    setOptionsOpen(false);
    setRenderNotifications(false);
    if (!readAt) {
      readNotification({ variables: { id, readAt: new Date().toUTCString() } });
    }
  };

  const notificationElements = buildNotificationElements({
    sender: fromUser as Partial<Users>,
    handleView,
    handleMarkAsRead,
    entityId,
    navigate,
  });

  let title = notificationElements[type].title;

  if (entityData) {
    if (
      entityType == EntityType_Enum.Appointments ||
      entityType == EntityType_Enum.Payments
    ) {
      title = `(${(entityData as Partial<Appointments>)?.service?.name}) ${
        notificationElements[type].title
      }`;
    } else if (entityType == EntityType_Enum.Services) {
      title =
        '(' +
        (entityData as Partial<Services>)?.name +
        ') ' +
        notificationElements[type].title;
    }
  }

  return (
    <div className="grid grid-cols-7 py-4">
      <div className="col-start-1 col-end-2">
        <div className="relative w-fit">
          <Avatar url={imgSrc} />
          {!readAt && (
            <Badge
              className="absolute -right-1 -top-1 h-2 w-2 rounded-full"
              variant="notification"
            />
          )}
        </div>
      </div>
      <div className="col-start-2 col-end-7">
        <p className="text-xs text-homy-gray-light">
          {differenceInMinutes(new Date(), new Date(createdAt)) <= 2
            ? t('labels.justNow')
            : format(new Date(createdAt), 'yyyy-MM-dd HH:mm')}
        </p>
        <p className="font-bold text-homy-gray-dark">{title}</p>
        <p className="mt-1 text-sm text-homy-gray">
          {notificationElements[type].subtitle}
        </p>
        {!loading && entityData ? (
          <NotificationBadge
            data={
              entityType == EntityType_Enum.Appointments ||
              entityType == EntityType_Enum.Payments
                ? ((entityData as Partial<Appointments>)
                    ?.service as Partial<Services>)
                : (entityData as Partial<Services>)
            }
            type={type}
          />
        ) : null}
      </div>
      <div className="cold-end-7 col-start-8" ref={dropdownDivRef}>
        <DropdownMenu
          open={optionsOpen}
          onOpenChange={setOptionsOpen}
          modal={false}
        >
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">{t('labels.openMenu')}</span>
              <RiMoreFill size={16} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent container={dropdownDivRef.current}>
            {notificationElements[type].options.map((option, i) => (
              <DropdownMenuItem
                key={`notification-option-${i}`}
                onClick={option.onClick}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
