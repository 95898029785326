import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/ui/button';
import { useCurrentUserQuery } from '~/hasura/__generated__';
import { LoadingSpinner } from '~/components/ui/spinner';
import { getInitials } from '~/utils/name';
import { prefixUrlWithRole } from '~/utils/navigation';

export function AccountInfo() {
  const { t } = useTranslation();
  const { loading, data } = useCurrentUserQuery();
  if (loading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="flex flex-row gap-3 sm:hidden">
      <Button className="h-10 w-10 items-center justify-center rounded-3xl border-2 border-white bg-homy-green-dark text-base shadow-md">
        {getInitials(data?.getCurrentUser?.name || '')}
      </Button>
      <div className="flex flex-col">
        <p className="font-bold">
          {t('messages.hello', { name: data?.getCurrentUser?.name })}
        </p>
        <Link to={prefixUrlWithRole('/profile-settings')}>
          <p className="text-xs text-homy-gray-light">
            {t('links.profileSettings')}
          </p>
        </Link>
      </div>
    </div>
  );
}
