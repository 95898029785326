import { RiNotificationOffFill } from '@remixicon/react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

export default function NotificationsEmptyState({
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <RiNotificationOffFill size={24} className="text-homy-gray-lighter" />
      <p className="text-xs text-homy-gray-light">
        {t('labels.noNotifications')}
      </p>
    </div>
  );
}
