import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from '@remix-run/react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  CustomerSearchSchema,
  CustomerSearchSchemaType,
} from '~/schemas/customer-search.schema';

interface IProps {
  onSearch?: () => void;
}

export function useSearchAddress(props: IProps | void) {
  const onSearchProp = props && props.onSearch;
  const [renderCustomerSearch, setRenderCustomerSearch] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const coordinates = searchParams.get('coordinates');
  const address = searchParams.get('address');
  const [lat, lng] = coordinates?.split(',').map(Number) || [];
  const form = useForm<CustomerSearchSchemaType>({
    defaultValues: {
      address: {
        value: {
          label: '',
          coordinates: [lat, lng],
        },
      },
    },
    resolver: zodResolver(CustomerSearchSchema),
  });

  const onSearch: SubmitHandler<CustomerSearchSchemaType> = (values) => {
    const searchParams = {
      address: values.address.value.label,
      coordinates: values.address.value.coordinates.join(','),
      ...(values.category ? { category: values.category } : {}),
    };
    navigate({
      pathname: '/search',
      search: new URLSearchParams(searchParams).toString(),
    });
    setRenderCustomerSearch(false);
    onSearchProp?.();
  };

  return {
    address,
    renderCustomerSearch,
    setRenderCustomerSearch,
    form,
    onSearch,
  };
}
