import { t } from 'i18next';
import { NavigateFunction } from '@remix-run/react';
import {
  Customers,
  NotificationType_Enum,
  UserRole_Enum,
  Users,
} from '~/hasura/__generated__';
import { prefixUrlWithRole } from '~/utils/navigation';
import { getUser } from '../../utils/localStorage';

export type TNotificationLabel = {
  title: string;
  subtitle: string;
  options: { label: string; onClick: () => void }[];
};

export interface INotificationLabelProps {
  sender: Partial<Users | Customers>;
  handleMarkAsRead: () => void;
  handleView: () => void;
  entityId: string;
  navigate: NavigateFunction;
}

type NotificationContent = {
  title: string;
  subtitle: string;
  options: { label: string; onClick: () => void }[];
};

export const buildNotificationElements = ({
  sender,
  handleMarkAsRead,
  handleView,
  entityId,
  navigate,
}: INotificationLabelProps): Record<
  NotificationType_Enum,
  NotificationContent
> => {
  const navigateFunction = (url: string) => () => {
    handleView();
    navigate(url);
  };
  const { role } = getUser();

  return {
    // ======== appointments ============
    [NotificationType_Enum.AutomaticApproval]: {
      title: sender?.name || 'Customer',
      subtitle: t('labels.bookedNewAppt'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(
            prefixUrlWithRole(`/appointment-details/${entityId}`),
          ),
        },
      ],
    },
    [NotificationType_Enum.WaitForApproval]: {
      title: sender?.name || 'Customer',
      subtitle: t('labels.sentNewApptReq'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(
            prefixUrlWithRole(`/appointment-details/${entityId}`),
          ),
        },
      ],
    },
    [NotificationType_Enum.AppointmentCancelled]: {
      title: sender?.name || 'Customer',
      subtitle: t('labels.cancelledAppt'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(
            prefixUrlWithRole(`/appointment-details/${entityId}`),
          ),
        },
      ],
    },

    // ======== partnerships ============

    [NotificationType_Enum.ApprovedPendingPartnership]: {
      title:
        sender?.location?.name ||
        (sender as Partial<Users>)?.userRole?.provider?.name ||
        sender?.name ||
        '',
      subtitle: t('labels.approvedPartnership'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewLocation'),
          onClick: navigateFunction(prefixUrlWithRole('/locations')),
        },
      ],
    },
    [NotificationType_Enum.NewApprovedPartnership]: {
      title:
        sender?.location?.name ||
        (sender as Partial<Users>)?.userRole?.provider?.name ||
        sender?.name ||
        '',
      subtitle: t('labels.newPartnership'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label:
            role === UserRole_Enum.Provider
              ? t('labels.viewLocation')
              : t('labels.viewServiceProvider'),
          onClick: navigateFunction(
            role === UserRole_Enum.Provider
              ? prefixUrlWithRole('/locations')
              : prefixUrlWithRole('/providers/processed'),
          ),
        },
      ],
    },
    [NotificationType_Enum.RejectedPendingPartnership]: {
      title:
        sender?.location?.name ||
        (sender as Partial<Users>)?.userRole?.provider?.name ||
        sender?.name ||
        '',
      subtitle: t('labels.rejectedPartnership'),
      options: [{ label: t('labels.markAsRead'), onClick: handleMarkAsRead }],
    },
    [NotificationType_Enum.NewPartnershipRequest]: {
      title:
        (sender as Partial<Users>)?.userRole?.provider?.name ||
        sender?.name ||
        '',
      subtitle: t('labels.newPartnershipRequest'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewServiceProvider'),
          onClick: navigateFunction(prefixUrlWithRole('/providers/pending')),
        },
      ],
    },

    // ======== payments ============
    [NotificationType_Enum.PaymentReceived]: {
      title: sender?.name || 'Customer',
      subtitle: t('labels.paymentReceived'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(
            prefixUrlWithRole(`/appointment-details/${entityId}`),
          ),
        },
      ],
    },

    [NotificationType_Enum.RefundReceived]: {
      title: sender?.name || 'Service Provider',
      subtitle: t('labels.refundReceived'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(
            prefixUrlWithRole(`/appointment-details/${entityId}`),
          ),
        },
      ],
    },

    [NotificationType_Enum.AppointmentUpdate]: {
      title:
        sender?.location?.name ||
        (sender as Partial<Users>)?.userRole?.provider?.name ||
        sender?.name ||
        'Customer',
      subtitle: t('labels.appointmentUpdate'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(
            prefixUrlWithRole(`/appointment-details/${entityId}`),
          ),
        },
      ],
    },

    // ======== services ============
    [NotificationType_Enum.ApprovedPendingService]: {
      title: 'Service Provider',
      subtitle: t('labels.serviceApproved'),
      options: [
        { label: t('labels.markAsRead'), onClick: handleMarkAsRead },
        {
          label: t('labels.viewAppointment'),
          onClick: navigateFunction(prefixUrlWithRole('/services')),
        },
      ],
    },
  };
};
