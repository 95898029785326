import { useClickAway } from '@uidotdev/usehooks';
import { useEffect, useMemo, useState } from 'react';
import { Button } from '~/components/ui/button';
import { Badge } from '~/components/ui/badge';
import { RiNotificationLine } from '@remixicon/react';
import Notifications from '~/components/notifications/notifications';
import {
  NewNotificationsDocument,
  NewNotificationsSubscription,
  NewNotificationsSubscriptionVariables,
  useNotificationsQuery,
  useViewAllNotificationsMutation,
} from '~/hasura/__generated__';
import { LoadingSpinner } from '~/components/ui/spinner';
import { addDays } from 'date-fns';
import { getUser } from '~/utils/localStorage';
import { isBrowser } from '~/constants';

export default function NotificationsBtn() {
  const [renderNotifications, setRenderNotifications] = useState(false);
  const user = getUser();
  const notificationsRef = useClickAway<HTMLDivElement>(() => {
    setRenderNotifications(false);
  });
  const nowDate = useMemo(() => new Date(), []);
  const [viewAllNotifications] = useViewAllNotificationsMutation();
  const {
    data: notificationsData,
    loading: loadingNotifications,
    fetchMore: fetchMoreNotifications,
    subscribeToMore: subscribeToMoreNotifications,
    updateQuery: updateNotificationsData,
  } = useNotificationsQuery({
    skip: !user.id || !isBrowser,
    variables: {
      limit: 6,
      beforeCreatedAt: addDays(nowDate.toUTCString(), 1).toUTCString(),
    },
  });
  const notificationsCount =
    notificationsData?.notifications_aggregate.aggregate?.count || 0;
  const notifications = notificationsData?.notifications;
  const hasOlderNotifications =
    (notifications?.length || 0) < (notificationsCount || 0);
  const unreadNotificationsAmount =
    notificationsData?.unreadNotificationsCount.aggregate?.count || 0;

  useEffect(() => {
    if (!user.id || !isBrowser) {
      return;
    }
    return subscribeToMoreNotifications<
      NewNotificationsSubscription,
      NewNotificationsSubscriptionVariables
    >({
      document: NewNotificationsDocument,
      variables: {
        afterCreatedAt: nowDate.toUTCString(),
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data.notifications_stream.length) {
          return prev;
        }
        const newNotificationsData = subscriptionData.data;

        return {
          ...prev,
          notifications: [
            ...newNotificationsData.notifications_stream,
            ...prev.notifications,
          ],
          unreadNotificationsCount: {
            ...prev.unreadNotificationsCount,
            aggregate: {
              ...prev.unreadNotificationsCount.aggregate,
              count:
                (prev.unreadNotificationsCount.aggregate?.count || 0) +
                newNotificationsData.notifications_stream.length,
            },
          },
          notifications_aggregate: {
            ...prev.notifications_aggregate,
            aggregate: {
              ...prev.notifications_aggregate.aggregate,
              count:
                (prev.notifications_aggregate.aggregate?.count || 0) +
                newNotificationsData.notifications_stream.length,
            },
          },
        };
      },
    });
  }, [nowDate, subscribeToMoreNotifications, user.id]);

  const onOpenNotifications = () => {
    setRenderNotifications(
      (prevRenderNotifications) => !prevRenderNotifications,
    );
  };

  const onLoadMoreNotifications = () => {
    if (!notifications || !hasOlderNotifications) return;
    const oldestNotification = notifications[notifications.length - 1];
    fetchMoreNotifications({
      variables: { beforeCreatedAt: oldestNotification.createdAt },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          notifications: [
            ...prev.notifications,
            ...fetchMoreResult.notifications,
          ],
        };
      },
    });
  };

  const onNotificationsClose = () => {
    if (!notifications?.length) return;
    viewAllNotifications({
      variables: { viewedAt: new Date().toUTCString() },
    });
  };

  if (loadingNotifications) return <LoadingSpinner />;
  return (
    <div className="relative" ref={notificationsRef}>
      <Button
        size="icon"
        className="relative bg-transparent hover:bg-transparent hover:shadow-none"
        onClick={onOpenNotifications}
      >
        {unreadNotificationsAmount && (
          <Badge
            className="absolute -top-0 left-4"
            variant="notification"
            size="md"
          >
            {unreadNotificationsAmount}
          </Badge>
        )}
        <RiNotificationLine size={24} className="text-homy-gray-darker" />
      </Button>

      {renderNotifications && (
        <div className="relative">
          <div className="absolute right-[10px] top-0 h-4 w-4 rotate-45 bg-white shadow-lg" />
          <Notifications
            className="absolute right-0 top-2"
            notificationsCount={notificationsCount}
            unreadNotificationsAmount={unreadNotificationsAmount}
            notifications={notifications}
            setRenderNotifications={setRenderNotifications}
            onLoadMore={onLoadMoreNotifications}
            loadingMore={loadingNotifications}
            updateNotificationsData={updateNotificationsData}
            onClose={onNotificationsClose}
          />
        </div>
      )}
    </div>
  );
}
