import { RiCheckDoubleFill } from '@remixicon/react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '~/utils/tw';
import {
  NotificationsQuery,
  NotificationsQueryVariables,
  useReadAllNotificationsMutation,
} from '~/hasura/__generated__';
import { Button } from '../ui/button';
import { ObservableQuery } from '@apollo/client';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  unreadNotificationsAmount: number;
  updateNotificationsData: ObservableQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >['updateQuery'];
}

export default function NotificationsHeader({
  unreadNotificationsAmount,
  updateNotificationsData,
  className,
  ...props
}: IProps) {
  const { t } = useTranslation();
  const [readAllNotifications] = useReadAllNotificationsMutation({
    onCompleted: () => {
      updateNotificationsData((prev) => ({
        ...prev,
        unreadNotificationsCount: {
          ...prev.unreadNotificationsCount,
          aggregate: { ...prev.unreadNotificationsCount.aggregate, count: 0 },
        },
      }));
    },
  });

  const onMarkAllRead = () => {
    readAllNotifications({ variables: { readAt: new Date().toUTCString() } });
  };

  return (
    <div
      {...props}
      className={cn('flex items-center justify-between', className)}
    >
      <p className="font-bold text-homy-gray-dark">
        {t('labels.notifications')} ({unreadNotificationsAmount})
      </p>
      <div className="flex items-center gap-1">
        <Button
          disabled={unreadNotificationsAmount === 0}
          variant="ghost"
          className={cn('p-0 text-xs font-bold text-success', {
            'text-homy-gray-light': unreadNotificationsAmount === 0,
          })}
          onClick={onMarkAllRead}
        >
          {t('labels.markAllRead')}
        </Button>
        <RiCheckDoubleFill
          className={cn('text-success', {
            'text-homy-gray-light': unreadNotificationsAmount === 0,
          })}
          size={16}
        />
      </div>
    </div>
  );
}
