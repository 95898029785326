import {
  EntityType_Enum,
  useAppointmentServiceNotificationLazyQuery,
  usePaymentServiceNotificationLazyQuery,
  useServicesServiceNotificationLazyQuery,
} from '~/hasura/__generated__';

export const fetchNotificationAffectedEntity = (
  entityType: EntityType_Enum,
) => {
  switch (entityType) {
    case EntityType_Enum.Appointments:
      return useAppointmentServiceNotificationLazyQuery;
    case EntityType_Enum.Payments:
      return usePaymentServiceNotificationLazyQuery;
    case EntityType_Enum.Services:
      return useServicesServiceNotificationLazyQuery;
    default:
      return undefined;
  }
};
