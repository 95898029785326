import { Link } from '@remix-run/react';
import { RiQuestionLine } from '@remixicon/react';
import { t } from 'i18next';
import { cn } from '../../utils/tw';

interface IProps {
  isUser?: boolean;
}

function FAQLinks({ isUser }: IProps) {
  return (
    <div
      className={cn(
        'flex h-32 flex-row gap-4 border-t border-t-homy-gray-lighter px-10 py-8',
        { 'group-hover:h-32 sm:h-16 lg:h-32': !isUser },
        { 'h-32': isUser },
      )}
    >
      <div>
        <RiQuestionLine className="h-6 w-6 text-homy-gray-light" />
      </div>
      <div
        className={cn('flex flex-col gap-2 group-hover:visible lg:visible', {
          'sm:invisible': !isUser,
        })}
      >
        <Link to="/" className="text-sm text-homy-gray-dark">
          {t('links.needHelp')}
        </Link>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.gohomy.ca/Help"
          className="text-xs text-homy-gray-light"
        >
          {t('links.reportProblem')}
        </a>
      </div>
    </div>
  );
}

export { FAQLinks };
