import { z } from 'zod';
import { ProviderCategory_Enum } from '../hasura/__generated__';
import { AddressSchema } from './address.schema';

export const CustomerSearchSchema = z
  .object({
    category: z.nativeEnum(ProviderCategory_Enum).optional(),
  })
  .merge(AddressSchema)
  .superRefine(({ address }, ctx) => {
    const { value, options = [] } = address;
    if (
      !options.find(
        ({ label, coordinates }) =>
          label === value?.label &&
          JSON.stringify(coordinates) === JSON.stringify(value?.coordinates),
      )
    ) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.selectAddressFromOptions',
        path: ['address'],
      });
    }
  });

export type CustomerSearchSchemaType = z.infer<typeof CustomerSearchSchema>;
