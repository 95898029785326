import { Link } from '@remix-run/react';
import { ComponentProps } from 'react';
import { cn } from '~/utils/tw';
import { LazyImage } from './lazy-image';

interface LogoProps extends Partial<ComponentProps<typeof Link>> {
  center?: boolean;
}

export const Logo = ({
  center = false,
  to = '/',
  className,
  ...props
}: LogoProps) => {
  return (
    <Link
      {...props}
      to={to}
      className={cn(className, {
        'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform':
          center,
      })}
    >
      {/* Desktop Logo */}
      <LazyImage
        src="/svg/logo.svg"
        alt="homy"
        className="hidden h-7 sm:block "
      />
      {/* Mobile Logo */}
      <LazyImage src="/svg/mobile-logo.svg" alt="homy" className="sm:hidden" />
    </Link>
  );
};
