import { useTranslation } from 'react-i18next';
import NotificationsHeader from './header';
import NotificationsEmptyState from './empty-state';
import Notification from './notification';
import { Separator } from '../ui/separator';
import {
  DetailedHTMLProps,
  Dispatch,
  Fragment,
  HTMLAttributes,
  SetStateAction,
  useEffect,
} from 'react';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { LoadingSpinner } from '../ui/spinner';
import { cn } from '~/utils/tw';
import {
  NotificationsQuery,
  NotificationsQueryVariables,
} from '~/hasura/__generated__';
import { ObservableQuery } from '@apollo/client';
import { S3_BUCKET_CDN_URL } from '~/constants';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  unreadNotificationsAmount: number;
  notifications?: NotificationsQuery['notifications'];
  notificationsCount: number;
  onLoadMore?: () => void;
  loadingMore?: boolean;
  setRenderNotifications: Dispatch<SetStateAction<boolean>>;
  updateNotificationsData: ObservableQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >['updateQuery'];
  onClose?: () => void;
}

export default function Notifications({
  unreadNotificationsAmount,
  notifications,
  notificationsCount,
  onLoadMore,
  loadingMore,
  className,
  setRenderNotifications,
  updateNotificationsData,
  onClose,
  ...props
}: IProps) {
  const { t } = useTranslation();
  const notViewedNotifications = notifications?.filter(
    (notification) => !notification.viewedAt,
  );
  const viewedNotifications = notifications?.filter(
    (notification) => !!notification.viewedAt,
  );
  const [intersectionRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });

  useEffect(() => {
    if (!entry?.isIntersecting) return;
    onLoadMore?.();
  }, [entry, onLoadMore]);

  useEffect(() => {
    return () => {
      onClose?.();
    };
  }, []);

  return (
    <div
      {...props}
      className={cn(
        'max-h-[600px] min-h-[600px] w-[375px] overflow-y-auto bg-white px-3 shadow-lg',
        className,
      )}
    >
      <NotificationsHeader
        unreadNotificationsAmount={unreadNotificationsAmount}
        updateNotificationsData={updateNotificationsData}
        className="px-1 pb-6 pt-4"
      />
      {notificationsCount === 0 ? (
        <NotificationsEmptyState className="flex flex-col items-center gap-3" />
      ) : (
        <>
          {!!notViewedNotifications?.length && (
            <div>
              <p className="font-bold text-homy-gray-darker">
                {t('labels.new')}
              </p>
              {notViewedNotifications.map((notification) => (
                <Fragment key={notification.id}>
                  <Notification
                    imgSrc={`${S3_BUCKET_CDN_URL}/${notification.fromUser?.profilePicture?.name}`}
                    setRenderNotifications={setRenderNotifications}
                    updateNotificationsData={updateNotificationsData}
                    {...notification}
                  />
                  <Separator className="-ml-3 h-[0.5px] w-[calc(100%+24px)] border-none  bg-homy-gray-lighter" />
                </Fragment>
              ))}
            </div>
          )}
          {!!viewedNotifications?.length && (
            <div>
              <p
                className={cn('pb-2 font-bold text-homy-gray-darker', {
                  'pt-4': !!notViewedNotifications?.length,
                })}
              >
                {t('labels.earlier')}
              </p>
              {viewedNotifications.map((notification) => (
                <Fragment key={notification.id}>
                  <Notification
                    imgSrc={`${S3_BUCKET_CDN_URL}/${notification.fromUser?.profilePicture?.name}`}
                    setRenderNotifications={setRenderNotifications}
                    updateNotificationsData={updateNotificationsData}
                    {...notification}
                  />
                  <Separator className="-ml-3 h-[0.5px] w-[calc(100%+24px)] border-none  bg-homy-gray-lighter" />
                </Fragment>
              ))}
            </div>
          )}
          {loadingMore ? (
            <LoadingSpinner />
          ) : (
            // todo: good to have - add button fallback to load more
            <div className="h-20" ref={intersectionRef} />
          )}
        </>
      )}
    </div>
  );
}
