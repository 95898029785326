import {
  Appointments,
  AppointmentServiceNotificationQuery,
  EntityType_Enum,
  PaymentServiceNotificationQuery,
  Services,
  ServicesServiceNotificationQuery,
} from '~/hasura/__generated__';

export const getEntityData = (
  entityType: EntityType_Enum,
  data:
    | PaymentServiceNotificationQuery
    | AppointmentServiceNotificationQuery
    | ServicesServiceNotificationQuery
    | undefined,
) => {
  switch (entityType) {
    case EntityType_Enum.Appointments:
      return (data as AppointmentServiceNotificationQuery)
        ?.appointments_by_pk as Partial<Appointments>;
    case EntityType_Enum.Payments:
      return (data as PaymentServiceNotificationQuery)?.payments_by_pk
        ?.appointment as Partial<Appointments>;
    case EntityType_Enum.Services:
      return (data as ServicesServiceNotificationQuery)
        ?.services_by_pk as Partial<Services>;
    default:
      return undefined;
  }
};
