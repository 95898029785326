import { Services, NotificationType_Enum } from '~/hasura/__generated__';
import fontColorContrast from 'font-color-contrast';
import { Badge } from '../ui/badge';
export interface INotificationBadge {
  data: Partial<Services>;
  type: NotificationType_Enum;
}

export const NotificationBadge = ({ data, type }: INotificationBadge) => {
  switch (type) {
    case NotificationType_Enum.AutomaticApproval:
      return (
        <div className="mt-4 flex items-center gap-2">
          <Badge className="max-w-[50%] bg-homy-gray-lighter/25 text-homy-gray">
            <p className="truncate">{data.id}</p>
          </Badge>
          <Badge
            className={`bg-[${data.color}] max-w-[50%] truncate`}
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              color: fontColorContrast(data.color || 'black'),
            }}
          >
            <p className="truncate">{data.name}</p>
          </Badge>
        </div>
      );
    default:
      return null;
  }
};
