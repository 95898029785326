import { SelectValue } from '@radix-ui/react-select';
import { Select, SelectContent, SelectItem, SelectTrigger } from './ui/select';
import { ProviderCategory_Enum } from '../hasura/__generated__';
import { providerCategoriesLabelsTags } from '../utils/formatters';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { RiFunctionLine, RiMapPin2Line, RiSearchLine } from '@remixicon/react';
import { Form as RemixForm } from '@remix-run/react';
import { Button } from './ui/button';
import {
  Form,
  FormAddressInput,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { CustomerSearchSchemaType } from '../schemas/customer-search.schema';
import { Label } from './ui/label';

interface IProps {
  onSubmit: SubmitHandler<CustomerSearchSchemaType>;
  form: UseFormReturn<CustomerSearchSchemaType>;
}

export default function CustomerSearch({ form, onSubmit }: IProps) {
  const { t } = useTranslation();
  const selectDivRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex" ref={selectDivRef}>
      <Form {...form}>
        <RemixForm
          method="GET"
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-2.5 sm:flex-row sm:items-center"
        >
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-white" htmlFor="address">
                  {t('labels.yourAddress')}
                </FormLabel>
                <FormControl>
                  <FormAddressInput
                    id="address"
                    startIcon={{
                      icon: (
                        <RiMapPin2Line size={20} className="text-homy-gray" />
                      ),
                    }}
                    aria-describedby="address"
                    placeholder={t('placeholders.typeAddress')}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-white" htmlFor="category-select">
                  {t('labels.categoryOptional')}
                </FormLabel>
                <FormControl>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <SelectTrigger>
                      <div className="flex items-center gap-2">
                        <RiFunctionLine className="text-homy-gray" />
                        <SelectValue placeholder={t('labels.chooseCategory')} />
                      </div>
                    </SelectTrigger>
                    <SelectContent id="category-select">
                      {Object.values(ProviderCategory_Enum).map((category) => (
                        <SelectItem
                          key={`provider-category-${category}`}
                          value={category}
                        >
                          {t(providerCategoriesLabelsTags[category])}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="w-full sm:w-auto">
            <Label className="after:content-['']">&ensp;</Label>
            <Button type="submit" className="w-full">
              <RiSearchLine size={20} className="text-white" />
              {t('labels.search')}
            </Button>
          </div>
        </RemixForm>
      </Form>
    </div>
  );
}
