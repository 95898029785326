import { PropsWithChildren, useEffect, useState } from 'react';
import { Button } from './ui/button';
import { RiCloseLine } from '@remixicon/react';

interface IProps {
  open?: boolean;
  handleClose?: () => void;
}

export default function Backdrop({
  open,
  handleClose,
  children,
}: PropsWithChildren<IProps>) {
  const [render, setRender] = useState(
    typeof open === 'undefined' ? true : open,
  );

  useEffect(() => {
    if (typeof open === 'undefined') return;
    setRender(open);
  }, [open]);

  const onCloseClick = () => {
    if (handleClose) {
      handleClose();
      return;
    }
    setRender(false);
  };

  if (!render) return null;
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-[#000000E5]">
      <Button
        onClick={onCloseClick}
        className="absolute right-8 top-8"
        variant="ghost"
        size="icon"
      >
        <RiCloseLine className="text-white" size={40} />
      </Button>
      <div>{children}</div>
    </div>
  );
}
