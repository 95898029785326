import { NavLink, useLocation } from '@remix-run/react';
import {
  RiDashboard3Line,
  RiServiceLine,
  RiFolderUserLine,
  RiTeamLine,
  RiMapPin2Line,
  RiDraftLine,
  RiSettings3Line,
  RiHome2Line,
  RiAppsLine,
  RiErrorWarningLine,
} from '@remixicon/react';
import { useTranslation } from 'react-i18next';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { cn } from '~/utils/tw';
import {
  UserRole_Enum,
  useProviderVisibilityQuery,
} from '~/hasura/__generated__';
import { getUser } from '~/utils/localStorage';
import { ReactNode, useMemo } from 'react';
import { Tooltip } from '~/components/ui/tooltip';

interface IProps {
  onClickHandler: () => void;
  showSidebar: boolean;
  isUser?: boolean;
}
export function NavLinks({ showSidebar, onClickHandler, isUser }: IProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const activePath = pathname?.split('/')?.[2];
  const { role } = getUser();

  const { data: visibility } = useProviderVisibilityQuery({
    skip: role !== UserRole_Enum.Provider,
  });

  const [
    activeLocationsError,
    activeServicesError,
    employeeWithSchedulesError,
    settingsError,
  ] = useMemo(() => {
    const providerVisibilityStatus = visibility?.getProviderVisibility?.status;
    if (!providerVisibilityStatus) return [];
    return [
      !providerVisibilityStatus.hasActiveLocations,
      !providerVisibilityStatus.hasActiveServices,
      !providerVisibilityStatus.hasEmployeesWithSchedules,
      !providerVisibilityStatus.hasGeneralSettings ||
        !providerVisibilityStatus.hasPublicProfileSettings ||
        !providerVisibilityStatus.hasPaymentMethod,
      //|| !providerVisibilityStatus.hasPayoutMethod,
    ];
  }, [visibility?.getProviderVisibility?.status]);

  const links: Partial<
    Record<
      UserRole_Enum,
      {
        label: string;
        icon: ReactNode;
        path: string;
        notificationValue: number;
        tooltip?: { icon: ReactNode; label: string };
      }[]
    >
  > = {
    [UserRole_Enum.Provider]: [
      {
        label: t('links.dashboard'),
        icon: <RiDashboard3Line className="h-6 w-6" />,
        path: '/sp/dashboard',
        notificationValue: 0,
      },
      {
        label: t('links.services'),
        icon: <RiServiceLine className="h-6 w-6" />,
        path: '/sp/services',
        notificationValue: 0,
        ...(activeServicesError && {
          tooltip: {
            icon: (
              <RiErrorWarningLine className="text-homy-orange-dark" size={20} />
            ),
            label: t('pages.settings.provider.services.activeServicesMissing'),
          },
        }),
      },
      {
        label: t('links.customers'),
        icon: <RiFolderUserLine className="h-6 w-6" />,
        path: '/sp/customers',
        notificationValue: 0,
      },
      {
        label: t('links.team'),
        icon: <RiTeamLine className="h-6 w-6" />,
        path: '/sp/team',
        notificationValue: 0,
        ...(employeeWithSchedulesError && {
          tooltip: {
            icon: (
              <RiErrorWarningLine className="text-homy-orange-dark" size={20} />
            ),
            label: t('pages.settings.provider.employees.scheduleMissing'),
          },
        }),
      },
      {
        label: t('links.locations'),
        icon: <RiMapPin2Line className="h-6 w-6" />,
        path: '/sp/locations',
        notificationValue: 0,
        ...(activeLocationsError && {
          tooltip: {
            icon: (
              <RiErrorWarningLine className="text-homy-orange-dark" size={20} />
            ),
            label: t(
              'pages.settings.provider.locations.activeLocationsMissing',
            ),
          },
        }),
      },
      {
        label: t('links.forms'),
        icon: <RiDraftLine className="h-6 w-6" />,
        path: '/sp/form-list',
        notificationValue: 0,
      },
      {
        label: t('links.settings'),
        icon: <RiSettings3Line className="h-6 w-6" />,
        path: '/sp/settings/general',
        notificationValue: 0,
        ...(settingsError && {
          tooltip: {
            icon: (
              <RiErrorWarningLine className="text-homy-orange-dark" size={20} />
            ),
            label: t('pages.settings.provider.settings.settingsMissing'),
          },
        }),
      },
    ],
    [UserRole_Enum.BuildingManager]: [
      {
        label: t('links.dashboard'),
        icon: <RiDashboard3Line className="h-6 w-6" />,
        path: '/bm/dashboard',
        notificationValue: 0,
      },
      {
        label: t('labels.serviceProviders'),
        icon: <RiServiceLine className="h-6 w-6" />,
        path: '/bm/providers/pending',
        notificationValue: 0,
      },
      {
        label: t('links.customers'),
        icon: <RiFolderUserLine className="h-6 w-6" />,
        path: '/bm/customers',
        notificationValue: 0,
      },
      {
        label: t('links.settings'),
        icon: <RiSettings3Line className="h-6 w-6" />,
        path: '/bm/settings/general',
        notificationValue: 0,
      },
    ],
    [UserRole_Enum.User]: [
      {
        label: t('links.home'),
        icon: <RiHome2Line className="h-6 w-6" />,
        path: '/search',
        notificationValue: 0,
      },
      {
        label: t('links.appointments'),
        icon: <RiAppsLine className="h-6 w-6" />,
        path: '/user/appointments/booked',
        notificationValue: 0,
      },
      {
        label: t('links.settings'),
        icon: <RiSettings3Line className="h-6 w-6" />,
        path: '/user/settings/general',
        notificationValue: 0,
      },
    ],
  };

  return (
    <div className="flex w-full flex-col gap-4">
      {links[role as UserRole_Enum]?.map(
        ({ label, icon, path, notificationValue, tooltip }) => (
          <NavLink key={label} to={path} onClick={onClickHandler}>
            <Button
              className={cn('relative w-full', {
                'border-l-primary bg-gradient-to-r from-primary/5 hover:border-l-primary':
                  activePath === path?.split('/')?.[2],
              })}
              variant="navigation"
            >
              <div>{icon}</div>
              <p
                className={
                  isUser
                    ? ''
                    : cn('group-hover:visible lg:visible', {
                        'visible sm:invisible': showSidebar,
                        invisible: !showSidebar,
                      })
                }
              >
                {label}
              </p>
              {notificationValue > 0 && (
                <Badge
                  className="group-hover:static sm:absolute sm:right-2 sm:top-0 lg:static"
                  variant="notification"
                  size="md"
                >
                  {notificationValue}
                </Badge>
              )}
              {tooltip && (
                <Tooltip>
                  <Tooltip.Trigger className="group-hover:static sm:absolute sm:right-1 sm:top-0 lg:static">
                    {tooltip.icon}
                  </Tooltip.Trigger>
                  <Tooltip.Content>{tooltip.label}</Tooltip.Content>
                </Tooltip>
              )}
            </Button>
          </NavLink>
        ),
      )}
    </div>
  );
}
